import * as React from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  //InputLabel,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import Swal from "sweetalert2";
import { logout } from "../api/authProvider";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useEffect, useReducer } from "react";
import { Endpoint } from "../api/base";
import Loading from "./Loading";
import { CheckboxWithLabel, Select } from "formik-material-ui";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import LogoutModal from "./components/LogoutModal";

const initialState = {
  languages: undefined,
  preferences: undefined,
  devices: undefined,
  loading: true,
  showLogoutModal: false,
};
function reducer(state, action) {
  switch (action.type) {
    case "setLanguages":
      return { ...state, languages: action.payload };
    case "setPreferences":
      return { ...state, preferences: action.payload };
    case "setDevices":
      return { ...state, devices: action.payload };
    case "setLoading":
      return { ...state, loading: action.payload };
    case "setShowLogoutModal":
      return { ...state, showLogoutModal: action.payload };
    default:
      throw new Error("Something went wrong!");
  }
}

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "120px",
    height: "auto",
  },
  logout: {
    position: "absolute",
    margin: "12px",
    padding: "3px",
    right: "8px",
  },
  box: {
    height: "auto",
    display: "flex",
    padding: 3,
    marginBottom: "15px",
  },
  leftBox: {
    alignItems: "center",
  },
  form: {
    height: "auto",
    display: "grid",
    padding: 3,
    marginBottom: "15px",
    marginLeft: "15%",
    marginRight: "15%",
  },
  centerForm: {
    justifyContent: "center",
    alignItems: "center",
  },
  child: {
    marginLeft: "20px",
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: "40px",
  },
  title: {
    margin: 0,
  },
  checkBox: {
    margin: 0,
  },
  backButton: {
    // padding: 4px 10px;
    // font-size: 0.8125rem;
    marginLeft: 23,
  },
}));

function ChangePreferences(props) {
  const [state, dispatch] = useReducer(reducer, initialState, undefined);

  const handleLogoutClose = () =>
    dispatch({ type: "setShowLogoutModal", payload: false });
  const handleLogoutShow = () =>
    dispatch({ type: "setShowLogoutModal", payload: true });
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      Endpoint.Preferences.get()
        .then((response) => {
          dispatch({ type: "setLoading", payload: false });
          console.info("hi" + response.data);
          dispatch({ type: "setPreferences", payload: response.data });
          let devices = response.data.devices.map((device) => ({
            id: device.devicePreferences.deviceId,
            notifyDevice: device.devicePreferences.notifyDevice,
            deviceName: device.deviceName,
          }));
          dispatch({ type: "setDevices", payload: devices });
        })
        .catch((err) => {
          dispatch({ type: "setLoading", payload: false });
          Swal.fire(
            "something went wrong",
            err.response ? err.response.data : err.message,
            "error"
          );
        });
      Endpoint.Languages.get()
        .then((response) => {
          dispatch({ type: "setLanguages", payload: response.data });
        })
        .catch((err) => {
          Swal.fire(
            "something went wrong",
            err.response ? err.response.data : err.message,
            "error"
          );
        });
    };
    fetchData();
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    let preferences = {
      ...values,
      devices: values.devices.map((device) => ({
        id: device.id,
        notifyDevice: device.notifyDevice,
      })),
    };
    Endpoint.Preferences.update(preferences)
      .then((response) => {
        setSubmitting(false);
        Swal.fire("Your preferences have been saved successfully").then(
          (result) => {
            props.history.push(`/app/`);
          }
        );
      })
      .catch((err) => {
        setSubmitting(false);
        Swal.fire(
          "something went wrong",
          err.response ? err.response.data : err.message,
          "error"
        );
      });
  };

  const resetLogin = function () {
    //setShow(false);
    //window.localStorage.clear();
    logout();
  };
  return !state.loading ? (
    <>
      <LogoutModal
        show={state.showLogoutModal}
        handleClose={handleLogoutClose}
        logout={resetLogin}
      />
      <Box component="div" m={0} className={classes.logout}>
        <IconButton
          component={Link}
          to="/app/help"
          size="small"
          variant="contained"
          color="primary"
        >
          <HelpOutlineIcon />
        </IconButton>
        <IconButton
          onClick={() => handleLogoutShow()}
          size="small"
          variant="contained"
          color="secondary"
        >
          <ExitToAppIcon />
        </IconButton>
      </Box>
      <Box
        component="div"
        m={1}
        className={`${classes.leftBox} ${classes.box}`}
      >
        <img className={classes.logo} alt="logo" src="./sanofi_Log.png" />
      </Box>
      <Box
        component="div"
        m={1}
        className={`${classes.leftBox} ${classes.box}`}
      >
        <Button
          component={Link}
          to="/"
          size="small"
          variant="contained"
          color="secondary"
          className={classes.backButton}
        >
          <ArrowBackIcon />
        </Button>
      </Box>

      <Formik
        enableReinitialize
        initialValues={{
          notifyMobileDevices:
            state?.preferences?.user?.userPreferences.notifyMobileDevices,
          notifyEmail: state?.preferences?.user?.userPreferences.notifyEmail,
          languageId: state?.preferences?.user?.userPreferences.languageId,
          devices: state?.devices,
          
        }}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <div>
              <div className={`${classes.centerForm} ${classes.form}`}>
                <Box
                  component="div"
                  m={1}
                  className={`${classes.leftBox} ${classes.box}`}
                >
                  <h4 className={classes.title}>
                    {state?.preferences?.user?.displayName}'s Preferences
                  </h4>
                </Box>
               
                <Box
                  component="div"
                  className={`${classes.leftBox} ${classes.box}`}
                >
                  <Field
                    type="checkbox"
                    className={classes.child}
                    component={CheckboxWithLabel}
                    name="notifyMobileDevices"
                    Label={{
                      label: "Mobile Devices",
                    }}
                  />
                  <Field
                    type="checkbox"
                    className={classes.child}
                    component={CheckboxWithLabel}
                    name="notifyEmail"
                    Label={{
                      label: "Email",
                    }}
                  />
                </Box>
                <Box
                  component="div"
                  m={1}
                  className={`${classes.leftBox} ${classes.box}`}
                >
                  <h6 className={classes.title}>
                    <strong>Language</strong>
                  </h6>
                </Box>
                <Box
                  component="div"
                  className={`${classes.leftBox} ${classes.box}`}
                >
                  <FormControl
                    className={classes.child}
                    fullWidth
                    variant="outlined"
                  >
                    <Field
                      children={{ labelId: "languageId" }}
                      type="select"
                      value={values?.languageId ?? ""}
                      component={Select}
                      name="languageId"
                    >
                      {state?.languages?.map((language, index) => (
                        <MenuItem key={index} value={language?.languageId}>
                          {language?.description.replace(/_/g, " ")}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Box>
                <Box
                  component="div"
                  m={1}
                  mt={4}
                  className={`${classes.leftBox} ${classes.box}`}
                >
                  <h6 className={classes.title}>
                    {" "}
                    <strong>Devices </strong>
                  </h6>
                </Box>
                {values?.devices?.map((device, index) => (
                  <Box
                    key={index}
                    component="div"
                    className={`${classes.leftBox} ${classes.box} ${classes.checkBox}`}
                  >
                    <Field
                      type="checkbox"
                      className={classes.child}
                      name={`devices.${index}.notifyDevice`}
                      component={CheckboxWithLabel}
                      Label={{ label: device.deviceName }}
                    />
                  </Box>
                ))}
                <Box
                  component="div"
                  m={1}
                  className={`${classes.centerBox} ${classes.box}`}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    style={{
                      width: "50%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    component={Link}
                    style={{
                      width: "50%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                    to="/"
                    variant="contained"
                    color="secondary"
                    className={classes.backButton}
                  >
                    Cancel
                  </Button>
                </Box>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  ) : (
    <Loading />
  );
}

export default ChangePreferences;
