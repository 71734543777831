import * as React from "react";
import Swal from "sweetalert2";
import {useEffect} from "react";
import {login} from "../api/authProvider";
import Loading from "./Loading";


function Login (props){
    useEffect(() => {
        const handleLogin = async (e) => {
            const token = await login().catch(err => {
                //something went wrong, please try again
                Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
            })
            if(token){
                props.history.push("/")
            }
        }
        handleLogin();
    }, [props.history]);

    return (
        <Loading />
    )
}

export default Login;