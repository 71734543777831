import * as React from "react";
import {
  Box,
  Button,
  IconButton,
  //InputLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { logout } from "../api/authProvider";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useReducer } from "react";
import LogoutModal from "./components/LogoutModal";
import ScrollToTop from "react-scroll-up";

function reducer(state, action) {
  switch (action.type) {
    case 'setShowLogoutModal':
      return {...state, showLogoutModal: action.payload};
    default:
      throw new Error("Something went wrong!");
  }
}

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "120px",
    height: "auto",
  },
  logout: {
    position: "absolute",
    margin: "12px",
    padding: "3px",
    right: "8px",
  },
  box: {
    height: "auto",
    display: "flex",
    marginBottom: "15px",
  },
  leftBox: {
    alignItems: "center",
  },
  centerForm: {
    justifyContent: "center",
    alignItems: "center",
    display: 'grid'
  },
  child: {
    marginLeft: "20px",
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: "40px",
  },
  checkBox: {
    margin: 0,
  },
  backButton: {
    // padding: 4px 10px;
    // font-size: 0.8125rem;
    marginLeft: 23,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignContent: 'space-between',
    marginTop: '120px',
    marginLeft: '50px',
    marginRight: '50px',
    '& div, h3, h4, p': {
      marginTop: '20px'
    }
  },
  methods: {
    margin: '0 10px !important',
    marginLeft: '10px'
  },
  title: {
    color: '#C7B1D3',
    fontWeight: 'bold',
    marginTop: '40px !important'
  },
  menu: {
    width: '100%',
    position: 'fixed',
    top: 0,
    backgroundColor: '#fff'
  },
  header: {
    color: '#424492',
    textAlign: 'center',
    marginBottom: '20px'
  },
  image: {
    width: '100%'
  },
  upButton: {
    backgroundColor: 'rgba(255,255,255,.9)',
    border: '2px solid #525ca3',
    borderRadius: '50px',
    color: '#525ca3',
    width: '50px',
    height: '50px',
    textAlign: 'center',
    lineHeight: '44px',
    display: 'inline-block'
  }
}));

function Help() {
  const [state, dispatch] = useReducer(reducer, {showLogoutModal: false}, undefined);

  const handleLogoutClose = () => dispatch({ type: 'setShowLogoutModal', payload: false });
  const handleLogoutShow = () => dispatch({ type: 'setShowLogoutModal', payload: true });
  const classes = useStyles();


  const resetLogin = function () {
    //setShow(false);
    //window.localStorage.clear();
    logout();
  };
  return  (

      <>
        <ScrollToTop showUnder={0} duration={600} >
          <IconButton
              size="small"
              variant="contained"
              color="primary"
              className={classes.upButton}
          >
            <ExpandLessIcon />
          </IconButton>
      </ScrollToTop>
        <LogoutModal show={state.showLogoutModal} handleClose={handleLogoutClose} logout={resetLogin}/>
        <div className={classes.menu}>
          <Box
              component="div"
              m={0}
              className={classes.logout}
          >
            <IconButton
                onClick={() => handleLogoutShow()}
                size="small"
                variant="contained"
                color="secondary"
            >
              <ExitToAppIcon />
            </IconButton>
          </Box>
          <Box
              component="div"
              m={1}
              className={`${classes.leftBox} ${classes.box}`}
          >
            <img className={classes.logo} alt="logo" src="./sanofi_Log.png" />
          </Box>
          <Box
              component="div"
              m={1}
              className={`${classes.leftBox} ${classes.box}`}
          >
            <Button
                component={Link}
                to="/"
                size="small"
                variant="contained"
                color="secondary"
                className={classes.backButton}
            >
              <ArrowBackIcon />
            </Button>
          </Box>
        </div>

        <div className={classes.content}>
          <h3 className={classes.header}>
            Password management in Azure
          </h3>
          <h4 className={classes.title}>
            1.	Register a Multi Factor Authentication
          </h4>
          <br/>
          <p>
            Sign into your account from this link:<br/><a target="_blank" rel="noreferrer" href="http://myaccount.microsoft.com/">http://myaccount.microsoft.com</a><br/>Use your email address
          </p>
          <div className={classes.centerForm}>
            <img className={classes.image} alt="help" src="./help/image1.png" />
          </div>
          <p>
            Provide your password
          </p>
          <div className={classes.centerForm}>
            <img className={classes.image} alt="help" src="./help/image2.png" />
          </div>
          <p>
            If you are requested to stay signed in, select the check box, and say yes
          </p>
          <div className={classes.centerForm}>
            <img className={classes.image} alt="help" src="./help/image3.png" />
          </div>
          <p>
            After a successful login you will be seeing your profile, click on the Security Info
          </p>
          <div className={classes.centerForm}>
            <img className={classes.image} alt="help" src="./help/image4.png" />
          </div>
          <p>
            Click on the Add method and select Authenticator app
          </p>
          <div className={classes.centerForm}>
            <img className={classes.image} alt="help" src="./help/image5.png" />
          </div>
          <p>
            Click Add
          </p>
          <div className={classes.centerForm}>
            <img className={classes.image} alt="help" src="./help/image6.png" />
          </div>
          <p>
            If you do not have the Microsoft Authenticator app, download it using the link. Do not use any other authenticator app, click Next
          </p>
          <div className={classes.centerForm}>
            <img className={classes.image} alt="help" src="./help/image7.png" />
          </div>
          <p>
            Follow the instructions on the pop-up windows
          </p>
          <div className={classes.centerForm}>
            <img className={classes.image} alt="help" src="./help/image8.png" />
          </div>
          <div className={classes.centerForm}>
            <img className={classes.image} alt="help" src="./help/image9.png" />
          </div>
          <div className={classes.centerForm}>
            <img className={classes.image} alt="help" src="./help/image10.png" />
          </div>
          <div className={classes.centerForm}>
            <img className={classes.image} alt="help" src="./help/image11.png" />
          </div>
          <p>
            You will be redirected again to Security Info with a successful registered sign-in method
          </p>
          <div className={classes.centerForm}>
            <img className={classes.image} alt="help" src="./help/image12.png" />
          </div>
          <p style={{marginBottom:0}}>
            Available sign-in Methods are:
          </p>
          <p className={classes.methods}>
            -  Mobile App
          </p>
          <p className={classes.methods}>
            -  Mobile phone
          </p>
          <p className={classes.methods}>
            -  Email
          </p>
          <p className={classes.methods}>
            -  Security questions
          </p>
          <p style={{margin:0}}>
            Note: if you have more than one method registered you can select the default method, by clicking on the change. After you register a method you will be requested to use this each time you want to change information on the Security Info
          </p>
          <div className={classes.centerForm}>
            <img className={classes.image} alt="help" src="./help/image12-1.png" />
          </div>
          <h4 className={classes.title}>
            2.	Change your password
          </h4>
          <p>
            On your Profile click on change password
          </p>
          <div className={classes.centerForm}>
            <img className={classes.image} alt="help" src="./help/image13.png" />
          </div>
          <p>
            Provide your old and two times your new password, following the sanofi password rules
          </p>
          <div className={classes.centerForm}>
            <img className={classes.image} alt="help" src="./help/image14.png" />
          </div>
          <p>
            You will be redirected to a page that is equal to your profile page
          </p>
          <div className={classes.centerForm}>
            <img className={classes.image} alt="help" src="./help/image15.png" />
          </div>
          <p>
            Note: The password synchronization to on-premise can take up to 30 minutes
          </p>
          <h4 className={classes.title}>
            3.	Reset your Password
          </h4>
          <p>
            You can reset your password from this link:<br/><a target="_blank" rel="noreferrer" href="https://aka.ms/sspr">https://aka.ms/sspr</a><br/>Provide your email address and the security question
          </p>

          <div className={classes.centerForm}>
            <img className={classes.image} alt="help" src="./help/image16.png" />
          </div>
          <p>
            Select I forgot my password
          </p>
          <div className={classes.centerForm}>
            <img className={classes.image} alt="help" src="./help/image17.png" />
          </div>
          <p>
            Note: You do not need to use the second option, your account in case it is logged out, will be reset after 30min
          </p>
          <p>
            You need to provide one of the registered methods to verify your identity
          </p>
          <div className={classes.centerForm}>
            <img className={classes.image} alt="help" src="./help/image18.png" />
          </div>
          <p>
          Enter your new password and confirm it once
          </p>
          <div className={classes.centerForm}>
            <img className={classes.image} alt="help" src="./help/image19.png" />
          </div>
          <p>
          You will be notified when your password has been reset successfully
          </p>
          <div className={classes.centerForm}>
            <img className={classes.image} alt="help" src="./help/image20.png" />
          </div>
        </div>
      </>
  );
}

export default Help;
