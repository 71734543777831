import axios from "axios";
import { getAccessToken } from './authProvider'

export const baseURL = process.env.REACT_APP_BASE_URL + "/api";
export const tenantId = process.env.REACT_APP_TENANT_ID;
export const app_registration_url = process.env.REACT_APP_APP_REGISTRATION;
export const clientId = process.env.REACT_APP_CLIENT_ID;

console.log("the base url is " + baseURL);
console.log(tenantId, app_registration_url, clientId);


axios.interceptors.request.use(async (config) => {
  const token = await getAccessToken().catch(e => { console.log(e) })
  const extendedConfig = {
    headers: { Authorization: `Bearer ${token?.accessToken}` }
  }
  return Object.assign({}, config, extendedConfig);
}, function (error) {
  return Promise.reject(error);
});
axios.defaults.baseURL = baseURL

export const Endpoint = {
  Languages: {
    get: _ => axios.get(`languages`)
  },
  Preferences: {
    get: _ => axios.get(`preferences`),
    update: data => axios.post(`preferences`, data)
  }
}