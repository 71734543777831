import React from 'react'
import {Box, CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
        alignItems: "center",
        display: "grid"
    },
    box: {
        height: "auto",
        display: "flex",
        padding: 3,
        marginBottom: "15px",
        marginLeft: "10%",
        marginRight: "10%",
    },
    centerBox: {
        justifyContent: "center",
        alignItems: "center"
    }
}));

export default function Loading(){
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Box
                component="div"
                className={`${classes.centerBox} ${classes.box}`}
            >
                <CircularProgress />
            </Box>
        </div>
    )
}