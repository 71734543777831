import './App.css';
import React, {useEffect, useReducer} from "react";
import {Route, Switch, Redirect, HashRouter, withRouter} from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Help from "./pages/Help";
import ChangePreferences from "./pages/ChangePreferences";
import {getAccessToken, handleRedirectPromise} from "./api/authProvider";
import Loading from "./pages/Loading";
import NotFound from "./pages/NotFound";
import {ThemeProvider} from "@material-ui/styles";
import {createMuiTheme} from "@material-ui/core";

const initialState = {token: null, isLoading: true}
function reducer (state, action)  {
    switch(action.type) {
        case 'setToken':
            return {...state, token: action.payload}
        case 'setIsLoading':
            return {...state, isLoading: action.payload}
        default:
            throw new Error("Something went wrong!")
    }
}

let theme = createMuiTheme({
    palette: {
        primary: {
            main: "#525ca3"
        },
        secondary: {
            main: "#cf483e"
        }
    },
    typography: {
        fontFamily: 'Arial',
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 50,
            },
            contained: {
                boxShadow: "none",
                "&:active": {
                    boxShadow: "none"
                }
            },
        },
    }
});


function App() {
    const [state, dispatch] = useReducer(reducer, initialState, undefined)
    useEffect(() => {
        const getToken = async () => {
            let tokenValue = await handleRedirectPromise().catch(e => { console.log(e) })

            if (!state.token){
                tokenValue = await getAccessToken().catch(e => { console.log(e) })
            }
            dispatch({ type: 'setToken', payload: tokenValue })
            dispatch({ type: 'setIsLoading', payload: false })
        }
        getToken();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return(
        <React.Fragment>
            { state.isLoading ? <Loading />:
                <HashRouter>
                    <ThemeProvider theme={theme}>
                        <Switch>
                            <Route exact path="/" render={() => <Redirect to="/app" />} />
                            <PrivateRoute path="/app" component={MainApp} isAuthenticated={!!state.token} />
                            <PublicRoute path="/login" component={Login} isAuthenticated={!!state.token} />
                            <Route component={NotFound} />
                        </Switch>
                    </ThemeProvider>
                </HashRouter>
            }
        </React.Fragment>
    );
}

const PrivateRoute = ({ component, isAuthenticated, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    React.createElement(component, props)
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {
                                from: props.location,
                            },
                        }}
                    />
                )
            }
        />
    );
}

const PublicRoute = ({ component, isAuthenticated,  ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <Redirect
                        to={{
                            pathname: "/",
                        }}
                    />
                ) : (
                    React.createElement(component, props)
                )
            }
        />
    );
}

const MainApp = withRouter(({match}) => {
    return (
        <Switch>
            <Route path={`${match.url}/`} exact component={Home} />
            <Route path={`${match.url}/help`} exact component={Help} />
            <Route path={`${match.url}/changePreferences`} component={ChangePreferences} />
            <Route component={NotFound} />
        </Switch>
    );
})

export default App;
