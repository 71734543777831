import Modal from "react-bootstrap/Modal";
import {Button} from "@material-ui/core";
import * as React from "react";


export default function LogoutModal(props) {
    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h6>Are you sure you want to exit?</h6>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>
                    You may close this app to end this session or you can Logout from your account.<br />
                    If you choose to logout, you will be prompted for your credentials again.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <Button variant="contained" color="primary" onClick={props.handleClose}>
                        Ok
                    </Button>
                </div>
                <div>
                    <Button variant="contained" color="secondary" onClick={props.logout}>
                        Logout
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );

}