import * as React from "react";
import {useEffect,useReducer} from "react";
import { Box, Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { logout  } from "../api/authProvider";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Swal from "sweetalert2";
import { Endpoint } from "../api/base";
import "bootstrap/dist/css/bootstrap.min.css";
import LogoutModal from "./components/LogoutModal";

const useStyles = makeStyles((theme) => ({
  logo: {
    position: "absolute",
    margin: "8px",
    width: "120px",
    height: "auto",
  },
  title:{
    color:"red"
  },
  logout: {
    position: "absolute",
    margin: "12px",
    marginTop: "20px",
    padding: "3px",
    right: "8px",
  },
  root: {
    height: "100vh",
    alignItems: "center",
    display: "grid",
  },
  box: {
    height: "auto",
    display: "flex",
    padding: 6,
    marginBottom: "22px",
    marginLeft: "10%",
    marginRight: "10%",
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    '@media screen and (max-width: 450px)': {
      width: "100%",
    },
    '@media screen and (min-width: 451px)': {
      width: "80%",
    },
    '@media screen and (min-width: 580px)': {
      width: "60%",
    },
    paddingTop: "10px",
    paddingBottom: "10px",
  }
}));
const initialState = {
  
  preferences: undefined,
 
  loading: true,
  showLogoutModal: false,
};
function reducer (state, action)  {
  switch(action.type) {
    case 'setShowLogoutModal':
      return {...state, showLogoutModal: action.payload};
    case "setPreferences":
      return { ...state, preferences: action.payload };
    case "setLoading":
        return { ...state, loading: action.payload };
    default:
      throw new Error("Something went wrong!")
  }
}

function Home() {
  const [state, dispatch] = useReducer(reducer, initialState, undefined);
  const classes = useStyles();

  const handleLogoutClose = () => dispatch({ type: 'setShowLogoutModal', payload: false });
  const handleLogoutShow = () => dispatch({ type: 'setShowLogoutModal', payload: true });

  const resetLogin = function () {
    //setShow(false);
    //window.localStorage.clear();
    logout();
  };
  useEffect(() => {
    const fetchData = async () => {
      Endpoint.Preferences.get()
        .then((response) => {
          dispatch({ type: "setLoading", payload: false });
          console.info("hi" + response.data);
          dispatch({ type: "setPreferences", payload: response.data });
         
          
        })
        .catch((err) => {
          dispatch({ type: "setLoading", payload: false });
          Swal.fire(
            "something went wrong",
            err.response ? err.response.data : err.message,
            "error"
          );
        });
      
    };
    fetchData();
  }, []);
  return (
      <>
        <img className={classes.logo} alt="logo" src="./sanofi_Log.png" />
        <Box
            component="div"
            m={0}
            className={classes.logout}
        >
          <IconButton
              component={Link}
              to="/app/help"
              size="small"
              variant="contained"
              color="primary"
          >
            <HelpOutlineIcon />
          </IconButton>
          <IconButton
              onClick={() => handleLogoutShow()}
              size="small"
              variant="contained"
              color="secondary"
          >
            <ExitToAppIcon />
          </IconButton>
        </Box>

        <div className={classes.root}>
          <LogoutModal show={state.showLogoutModal} handleClose={handleLogoutClose} logout={resetLogin}/>

          <div>
          <Box
                  component="div"
                  m={1}
                  className={`${classes.centerBox} ${classes.box}`}
                >
                  <h6 className={classes.title} >
                     {state?.preferences?.passwordExpiryDate }
                  </h6>
                </Box>
            <Box
                component="div"
                m={1}
                className={`${classes.centerBox} ${classes.box}`}
            >
              <Button
                  target="_blank"
                  href="https://account.activedirectory.windowsazure.com/ChangePassword.aspx"
                  size="large"
                  variant="contained"
                  color="primary"
                  className={classes.button}
              >
                Change Your Password
              </Button>
            </Box>
            <Box
                component="div"
                m={1}
                className={`${classes.centerBox} ${classes.box}`}
            >
              <Button
                  target="_blank"
                  href="https://aka.ms/sspr"
                  size="large"
                  variant="contained"
                  color="primary"
                  className={classes.button}
              >
                Reset Your Password
              </Button>
            </Box>
            <Box
                component="div"
                m={1}
                className={`${classes.centerBox} ${classes.box}`}
            >
              <Button
                  component={Link}
                  to="/app/changePreferences"
                  size="large"
                  variant="contained"
                  color="primary"
                  className={classes.button}
              >
                Change Your Preferences
              </Button>
            </Box>
          </div>
        </div>
      </>
  );
}

export default Home;
